/* generated by pull.js */
const manifest = {
  "name": "Reverse order of project controls",
  "description": "Moves the green flag and stop buttons to the right and the full screen button to the left, like in Scratch 2.0.",
  "tags": [
    "theme"
  ],
  "dynamicDisable": true,
  "userstyles": [
    {
      "url": "userstyle.css"
    }
  ]
};
export default manifest;
