/* generated by pull.js */
const manifest = {
  "editorOnly": true,
  "name": "Clone counter",
  "description": "Adds a counter above the stage in the editor which shows the total amount of clones.",
  "credits": [
    {
      "name": "Jeffalo"
    },
    {
      "name": "OregSam",
      "link": "https://scratch.mit.edu/users/simiagain/"
    }
  ],
  "dynamicDisable": true,
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "userstyles": [
    {
      "url": "style.css"
    }
  ],
  "settings": [
    {
      "dynamic": true,
      "id": "showicononly",
      "name": "Show icon only",
      "default": false,
      "type": "boolean"
    }
  ],
  "tags": [
    "recommended"
  ],
  "enabledByDefault": false
};
export default manifest;
