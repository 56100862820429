/* generated by pull.js */
const manifest = {
  "noTranslations": true,
  "name": "File drag and drop",
  "description": "Lets you drag images and sounds from your file manager into the sprite pane or costume/sound list. You can also drag text files into lists or \"ask and wait\" question inputs.",
  "credits": [
    {
      "name": "Sheep_maker"
    }
  ],
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "settings": [
    {
      "dynamic": true,
      "id": "use-hd-upload",
      "name": "Use HD uploads",
      "type": "boolean",
      "default": false,
      "if": {
        "addonEnabled": "better-img-uploads"
      }
    }
  ],
  "dynamicDisable": true,
  "tags": [
    "recommended"
  ],
  "enabledByDefault": true
};
export default manifest;
