/* generated by pull.js */
const manifest = {
  "editorOnly": true,
  "noTranslations": true,
  "name": "Cat blocks",
  "description": "Brings back editor cat hat blocks from April Fools 2020.",
  "credits": [
    {
      "name": "TheColaber",
      "link": "https://scratch.mit.edu/users/TheColaber/"
    },
    {
      "name": "GarboMuffin"
    }
  ],
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "info": [
    {
      "type": "notice",
      "text": "The \"watch mouse cursor\" setting may impact performance when the editor is open.",
      "id": "watch"
    }
  ],
  "settings": [
    {
      "id": "watch",
      "name": "Watch mouse cursor",
      "default": false,
      "type": "boolean"
    }
  ],
  "tags": [
    "theme"
  ],
  "enabledByDefault": false
};
export default manifest;
