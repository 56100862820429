/* generated by pull.js */
const manifest = {
  "editorOnly": true,
  "enabledByDefault": true,
  "name": "Rename broadcasts",
  "description": "Adds an option to rename broadcast messages in the broadcast blocks' dropdowns.",
  "credits": [
    {
      "name": "TheColaber",
      "link": "https://scratch.mit.edu/users/TheColaber"
    },
    {
      "name": "GarboMuffin"
    }
  ],
  "tags": [
    "new"
  ],
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "dynamicDisable": true
};
export default manifest;
